import { useEffect, useCallback } from 'react';

export default function useClickOutside(isListening, targetRef, handler) {
  const clickListener = useCallback(event => {
    if (!targetRef.current.contains(event.target)) {
      handler(event);
    }
  });

  useEffect(() => {
    if (isListening) document.addEventListener('mousedown', clickListener);

    return () => {
      document.removeEventListener('mousedown', clickListener);
    };
  }, [isListening]);
}
