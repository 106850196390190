import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'gatsby';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { categories } from '../../data/data.json';
import './style.css';

const categoriesArr = Object.values(categories);

const CategoryBar = ({ id }) => {
  const { width } = useWindowDimensions();

  const menu = (
    <Menu>
      {categoriesArr.map(category => (
        <Menu.Item className="menu-item" key={category.id}>
          <Link to={`/products/${category.id}`}>{category.name}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  const renderDropdown = () => {
    const { name } = categoriesArr.find(categ => categ.id === +id);
    return (
      <Dropdown overlay={menu} getPopupContainer={trigger => trigger.parentNode}>
        <div className="bar-name">
          {name}
          <DownOutlined />
        </div>
      </Dropdown>
    );
  };

  return (
    <div className="bar">
      {!(id && width <= 600) && (
        <Link to="/products/" className={`bar-category-title ${id ? '' : 'bar-name'}`}>
          Preservació /
        </Link>
      )}
      {id && renderDropdown()}
    </div>
  );
};

CategoryBar.propTypes = {
  id: PropTypes.string,
};

export default CategoryBar;
