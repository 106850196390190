import React from 'react';
import './style.css';

const BurgerMenu = ({ open, setOpen, isWhite }) => {
  return (
    <button
      className={`burger ${open ? 'burger-cross' : ''} ${isWhite ? 'light' : ''}`}
      type="button"
      open={open}
      onClick={() => setOpen((prev) => !prev)}
    >
      <div />
      <div />
      <div />
    </button>
  );
};

export default BurgerMenu;
