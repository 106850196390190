import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'gatsby';
import './style.css';
import BurgerMenu from '../burgerMenu';
import SearchBar from '../searchBar';

const Navbar = ({ pathname, navigate, isDrawerOpen, setIsDrawerOpen }) => {
  const drawerRef = useRef(null);
  const menuRef = useRef(null);
  const burgerRef = useRef(null);
  const clickListener = useCallback(event => {
    if (!menuRef.current.contains(event.target) && !drawerRef.current.contains(event.target) && !burgerRef.current.contains(event.target)) {
      setIsDrawerOpen(false);
    }
  });

  const search = useCallback(content => {
    navigate(`/search/${content}`);
  });

  useEffect(() => {
    if (isDrawerOpen) document.addEventListener('mousedown', clickListener);

    return () => {
      document.removeEventListener('mousedown', clickListener);
    };
  }, [isDrawerOpen]);

  const closeDrawer = () => setIsDrawerOpen(false); 

  const renderMenu = () => (
    <div className="menu">
      <Link to="/products" activeClassName="selectedTitle" partiallyActive>
        Productes
      </Link>
      <Link to="/aboutus" activeClassName="selectedTitle">
        Qui som
      </Link>
      <Link to="/contact" activeClassName="selectedTitle">
        Contacta
      </Link>
    </div>
  );

  const renderDrawer = () => (
    <div
      ref={drawerRef}
      className={`drawer-menu ${isDrawerOpen ? 'drawer-menu_open' : 'drawer-menu_closed'}`}
    >
      <Link to="/products" activeClassName="selectedTitle" partiallyActive onClick={closeDrawer}>
        Productes
      </Link>
      <Link to="/aboutus" activeClassName="selectedTitle" onClick={closeDrawer}>
        Qui som
      </Link>
      <Link to="/contact" activeClassName="selectedTitle" onClick={closeDrawer}>
        Contacta
      </Link>
    </div>
  );

  return (
    <div
      className="navbar"
      style={{
        backgroundColor: `rgba(255, 255, 255, ${pathname === '/' ? 0 : 1})`,
      }}
    >
      {renderDrawer()}
      <div ref={menuRef} className="menu-container">
        {!isDrawerOpen && renderMenu()}
      </div>
      <SearchBar pathname={pathname} submit={search} />
      <div className="burger_container" ref={burgerRef}>
        <BurgerMenu open={isDrawerOpen} setOpen={setIsDrawerOpen} isWhite={pathname === '/'} />
      </div>
    </div>
  );
};

export default Navbar;
