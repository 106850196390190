import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';
import './layout.css';
import LogoDark from '../images/jcr-dark.png';
import LogoLight from '../images/jcr-light.png';
import Logo from '../images/jcr.png';
import Navbar from '../components/navbar';
import CategoryBar from '../components/categoryBar';
import ResultSearchBar from '../components/resultSearchBar';

const Layout = ({ children, location, navigate }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [, , param] = location.pathname.split('/'); // eslint-disable-line no-unused-vars

  return (
    <div className="container">
      <Link to="/">
          <img className="logo" src={Logo} alt="jcr" />
          <img className={`${location.pathname === '/' ? '' : 'hide'} logo`} src={LogoDark} alt="jcr" />
          <img className={`${isDrawerOpen ? '' : 'hide'} logo`} src={LogoLight} alt="jcr" />
        </Link>
      <Navbar {...location} navigate={navigate} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
      <Router>
        <CategoryBar path="/products/*" id={param} />
        <ResultSearchBar path="/search/*" result={param} />
      </Router>
      <div className="pageContent">{children}</div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
