import React from 'react';
import './style.css';

const ResultSearchBar = ({ result }) => {
  return (
    <div className="result-bar">
      <p className="result-bar-title">Resultat de la búsqueda</p>
      <p className="result-bar-content">{`"${decodeURI(result)}"`}</p>
    </div>
  );
};

export default ResultSearchBar;
