import React, { useState, useCallback, useRef, useEffect } from 'react';
import magWhite from '../../images/magnifying_glass_white.png';
import magGrey from '../../images/magnifying_glass_grey.png';
import './style.css';
import useClickOutside from '../../hooks/useClickOutside';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const SearchBar = ({ pathname, submit }) => {
  const [focused, setFocused] = useState(false);
  const [content, setContent] = useState('');
  const [inputWith, setInputWith] = useState('');
  const [isPopUp, setIsPopUp] = useState(false);
  const searchContainerRef = useRef(null);
  const mainInputRef = useRef(null);
  const popUpRef = useRef(null);
  const popUpInputRef = useRef(null);
  const { width } = useWindowDimensions();

  const toggleFocus = useCallback(() => {
    if (!focused) {
      setContent('');
      if (!isPopUp) setTimeout(() => mainInputRef.current.focus(), 300);
    }
    setFocused(!focused);
  });

  useClickOutside(focused && !isPopUp, searchContainerRef, toggleFocus);
  useClickOutside(focused && isPopUp, popUpRef, toggleFocus);

  useEffect(() => {
    if (width < 400) setIsPopUp(true);
    else setIsPopUp(false);
  }, [width]);

  const handleSubmit = event => {
    event.preventDefault();
    if (focused && content) submit(content);
    else toggleFocus();
  };

  const handleInputChange = event => {
    if (!isPopUp) {
      const searchContainerRefWidth = searchContainerRef.current.scrollWidth;
      const mainInputRefWith = mainInputRef.current.scrollWidth;

      if (searchContainerRefWidth * 0.62 < mainInputRefWith) setInputWith(mainInputRefWith);
    }
    setContent(event.target.value);
  };

  const rednerMainBar = () => (
    <form
      style={{ width: inputWith }}
      className={`search-input-container${focused ? '_open' : '_closed'}`}
      onSubmit={handleSubmit}
    >
      <input
        type="text"
        ref={mainInputRef}
        placeholder="Busca..."
        value={content}
        onChange={handleInputChange}
      />
    </form>
  );

  const renderPopUpBar = () => (
    <div className={`popup-container popup-container${focused ? '_open' : '_closed'}`}>
      <div className="popup-inner" ref={popUpRef}>
        <p>Busca</p>
        <form
          style={{ width: '100%' }}
          className="search-input-container_open"
          onSubmit={handleSubmit}
        >
          <input
            type="text"
            ref={popUpInputRef}
            placeholder="..."
            value={content}
            onChange={handleInputChange}
          />
          <button type="button" className="icon-btn">
            <img src={magGrey} alt="jcr" />
          </button>
        </form>
      </div>
    </div>
  );

  return (
    <div
      className={`search-container ${pathname === '/' ? '' : 'search-container_dark'}`}
      ref={searchContainerRef}
    >
      {isPopUp ? renderPopUpBar() : rednerMainBar()}
      <button type="button" className="icon-btn" onClick={handleSubmit}>
        <img src={pathname === '/' ? magWhite : magGrey} alt="jcr" />
      </button>
    </div>
  );
};

export default SearchBar;
